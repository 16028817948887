import { Accordion, AccordionHeader, AccordionItem, AccordionPanel, Button, Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, Switch, Text, makeStyles, shorthands, tokens, Toast, ToastTitle, Toaster, useToastController, useId, Spinner } from "@fluentui/react-components";
import Form from "@rjsf/fluentui-rc";
import validator from "@rjsf/validator-ajv8";
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { PluginResponse, PluginsService, ProjectAccessResponse, ProjectsService, WorkflowTemplatesService, WorkflowsService } from '../services/openapi';
import { WorkflowCard } from './WorkflowCard';
import { PrimaryButton } from "./common/PrimaryButton";
import InsufficientCreditModal from "./InsufficientCreditModal";
import { NodeSchema, getSchema, uiSchema } from "./utils/getSchema";
import ToastErrorIcon from "../assets/icons/toast_error.svg";

interface Props {
    label?: string;
    style?: React.CSSProperties,
    className?: string,
    projectId: string;
    projectName?: string;
    flightId: string;
    flightName?: string;
    groupId?: string,
    appearance?: 'secondary' | 'primary' | 'outline' | 'subtle' | 'transparent';
    buttonID?: string;
    isDisabled?: boolean;
    numOfFlightImages?: number;
}


interface WorkflowTemplateResponseProps {
    author?: string | null;
    available: boolean;
    description?: string | null;
    id: string;
    json: any;
    name: string;
}

export const StartWorkflowButton = (props: Props) => {
    const { isDisabled = false, numOfFlightImages = 0 } = props;
    const [templates, setTemplates] = React.useState<WorkflowTemplateResponseProps[]>([]);
    const [selectedTemplateId, setSelectedTemplateId] = React.useState('');
    const [selectedGroupId, setSelectedGroupId] = React.useState(undefined as string | undefined);
    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState('');
    const [plugins, setPlugins] = React.useState<Array<PluginResponse>>();
    const [schemaList, setSchemaList] = React.useState<NodeSchema[]>([]);
    const [formValues, setFormValues] = React.useState<Record<string, string>>({});
    const [projectAccess, setProjectAccess] = React.useState<ProjectAccessResponse | null>(null);
    const [insufficientCreditModalVisible, setInsufficientCreditModalVisible] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const { dispatchToast } = useToastController("failed");

    const failureToast = (message: string) => dispatchToast(
        <Toast
            style={{ background: "rgba(253, 231, 233, 1)", width: "100%" }}>
            <ToastTitle style={{ fontSize: "14px", fontWeight: 400 }} media={<img src={ToastErrorIcon} alt="error icon" style={{ marginRight: "0.25rem" }} />}>{message}</ToastTitle>
        </Toast>,
        { intent: "error" }
    );

    React.useEffect(() => {
        if (!props.projectId) return;
        ProjectsService.checkUserProjectAccess(props.projectId).then(permissions => {
            setProjectAccess(permissions)
        }).catch(err => console.error("Unable to fetch project access"))
    }, []);

    const canUploadData = !!projectAccess && projectAccess.canUploadData;

    const navigate = useNavigate();
    const classes = useStyles()


    const showDialog = () => {
        setSelectedTemplateId('');
        setOpen(true);
        getTemplatesData();
        PluginsService.getPlugins()
            .then(plugins => setPlugins(plugins.plugins))
            .catch(err => console.error("ERROR GETTING PLUGINS : ", err));
    }

    const getTemplatesData = (groupId?: string) => {
        setLoading(true)
        WorkflowTemplatesService.getTemplates(props.flightId, groupId || undefined)
            .then((ts) => {
                setTemplates(ts.templates);
                setOpen(true);
                setLoading(false)
            })
            .catch((err) => {
                setError(`Could not get workflow templates: ${err}`)
                setLoading(false)
            }
            );
    }

    const validateImagesPresence = () => {
        if (templates.length === 0) return false;
        const workflowConfig = templates.find(item => item.id === selectedTemplateId);
        if (!workflowConfig) return false;
        const isPrepareDatasetPresent = workflowConfig?.json?.nodes?.some(
            (node: { plugin_id?: string }) => node?.plugin_id === 'PREPARE_DATASET'
        );
        return !(isPrepareDatasetPresent && numOfFlightImages === 0);
    }

    const startWorflow = () => {
        if (!selectedTemplateId) return;
        if (!validateImagesPresence()) {
            failureToast("Can't start workflow: Please upload images to start")
            return
        };
        if (selectedGroupId) {
            WorkflowsService.createGroupWorkflow(props.projectId, selectedGroupId, { templateId: selectedTemplateId, config: formValues })
                .then((wf) => {
                    setOpen(false);
                    navigate(`/workflows/${wf.id}`);
                })
                .catch((err) => {
                    setError(`Could not start workflow: ${err}`)
                    console.table(err)
                    failureToast(err.body ? err.body : "Could not start workflow")
                });
        } else {
            WorkflowsService.createFlightWorkflow(props.flightId, { templateId: selectedTemplateId, config: formValues })
                .then((wf) => {
                    setOpen(false);
                    navigate(`/workflows/${wf.id}`);
                })
                .catch((err) => {
                    setError(`Could not start workflow: ${err}`)
                    console.table(err)
                    failureToast(err.body ? err.body : "Could not start workflow")
                });
        }
    };

    React.useEffect(() => {
        if (!open) {
            setFormValues({});
            setSchemaList([])
        }
    }, [open])

    if (!open) {
        return <PrimaryButton disabled={!canUploadData || isDisabled} label="Start New Workflow" onClick={() => showDialog()} id={props.buttonID} />
    }

    const buttonText = (templates || []).find((t) => t.id === selectedTemplateId)?.name || '-- Select a workflow template --';
    const description = (templates || []).find((t) => t.id === selectedTemplateId)?.description || '';

    const isButtonDisabled = !selectedTemplateId


    const toggleInsufficientCreditModal = (flag: boolean) => {
        setInsufficientCreditModalVisible(flag)
    };

    const handleSelectedTemplate = (selectedTemplate: any) => {
        if (selectedTemplate && selectedTemplate.available) {
            setSelectedTemplateId(selectedTemplate.id);
            getSchema(selectedTemplate, setSchemaList, plugins)
        } else if (!selectedTemplate.available) {
            toggleInsufficientCreditModal(true)
        }
    }

    return (
        <>
            <Dialog modalType='non-modal' open={open} onOpenChange={(_, d) => setOpen(d.open)}>
                <DialogTrigger>
                    <div>
                        <PrimaryButton label="Start New Workflow" />
                    </div>
                </DialogTrigger>
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle className={classes.title}>
                            {(props.projectName && props.flightName) ?
                                <>Initiate workflow for flight {props.flightName} in project {props.projectName}</>
                                : <>Initiate workflow</>
                            }

                        </DialogTitle>
                        <DialogContent style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, gap: "20px", marginTop: "20px" }}>
                            {props.groupId && (<div className={classes.select}>
                                <Switch
                                    value={selectedGroupId}
                                    onChange={(v, data) => {
                                        if (data.checked) {
                                            setSelectedGroupId(props.groupId);
                                        } else {
                                            setSelectedGroupId(undefined);
                                            getTemplatesData(props.groupId)
                                        }
                                    }}
                                /> Initiate workflow for Group ID {props.groupId}
                            </div>)}

                            <Menu positioning={'below-start'}>
                                <MenuTrigger     >
                                    <div >
                                        <Button size='large' style={{ width: "100%" }}>
                                            {buttonText}
                                        </Button>
                                        <div><Text size={200}>{description}</Text></div>
                                    </div>
                                </MenuTrigger>
                                <MenuPopover style={{ width: "550px", maxWidth: "550px" }}>
                                    <MenuList style={{ overflowY: 'auto', maxHeight: '20em' }}>
                                        {loading ? (
                                            <Spinner />
                                        ) : (
                                            templates.map((t) => (
                                                <MenuItem
                                                    key={t.id}
                                                    style={{
                                                        backgroundColor: tokens.colorNeutralBackground1,
                                                        minWidth: "100%",
                                                        opacity: !t.available ? 0.5 : 1,
                                                    }}
                                                    onClick={() => handleSelectedTemplate(t)}
                                                >
                                                    <WorkflowCard template={t} />
                                                </MenuItem>
                                            ))
                                        )}
                                    </MenuList>
                                </MenuPopover>
                            </Menu>

                            {(schemaList && schemaList.length > 0) && <Accordion collapsible>
                                <AccordionItem value="1">
                                    <AccordionHeader>Config</AccordionHeader>
                                    <AccordionPanel >
                                        <Accordion collapsible>
                                            {schemaList.map((nodeSchema, idx) =>
                                                <AccordionItem value={idx}>
                                                    <AccordionHeader>{nodeSchema.nodeId}</AccordionHeader>
                                                    <AccordionPanel >
                                                        <Form
                                                            formData={formValues[nodeSchema.nodeId] || {}}
                                                            schema={nodeSchema.schema}
                                                            validator={validator}
                                                            onChange={(e) => {
                                                                const nodeForm = {} as any;
                                                                if (e.formData) {
                                                                    nodeForm[nodeSchema.nodeId] = e.formData;
                                                                }
                                                                setFormValues(prev => ({
                                                                    ...prev,
                                                                    ...nodeForm,
                                                                }))
                                                            }}
                                                            uiSchema={uiSchema}
                                                        />
                                                    </AccordionPanel>
                                                </AccordionItem>
                                            )}
                                        </Accordion>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>}

                            <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end', gap: "16px" }}>
                                <Button onClick={() => setOpen(false)} style={{ borderRadius: "8px" }}>Cancel</Button>
                                <PrimaryButton label="Start" onClick={startWorflow} disabled={isButtonDisabled} />
                            </div>
                            <InsufficientCreditModal
                                visible={insufficientCreditModalVisible}
                                toggleModal={toggleInsufficientCreditModal}
                            />
                        </DialogContent>
                    </DialogBody>
                </DialogSurface>
            </Dialog >
            <Toaster toasterId={"failed"} position="bottom" limit={1} className={classes.toaster} />
        </>
    )
}


const useStyles = makeStyles({
    title: {
        color: "#586A84",
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "28px",
    },
    select: {
        color: "#586A84",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "24px",
        display: "flex",
        ...shorthands.gap("6px"),
        alignItems: "center"
    },
    toaster: {
        width: "600px",
    },
})