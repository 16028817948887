import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, makeStyles, Spinner } from '@fluentui/react-components';
import downloadLayerIcon from "../assets/icons/ol/measurement_tools/downloadLayers.svg";
import newTabIcon from "../assets/icons/ol/measurement_tools/newTab.svg";
import { MapLayerResponse, ReportsService, WorkflowsService, ReportResponse, WorkflowTemplatesService, FileResponse, FileTypeResponse } from '../services/openapi';

interface ModalProps {
    visible: boolean;
    toggleModal: (flag: boolean) => void;
    notifySucess: (msg: string) => void;
    layers: MapLayerResponse[];
}

interface FileData {
    id: string;
    workflowId: string;
    file_type: string;
    status: string;
    size_mb?: number | null;
    defaultName: string;
    description: string;
}

function DownloadReports(props: ModalProps) {
    const { visible, toggleModal, notifySucess, layers } = props;
    const classes = useStyles();
    const [downloadData, setDownloadData] = useState({
        wfOutputData: [] as FileData[],
        pdfData: [] as ReportResponse[],
    });
    const [loading, setLoading] = React.useState(true);

    const mergedData = (files: FileResponse[], filteredFileTypes: FileTypeResponse[], wrId: string) => {
        const mergedFiles = files.map(file => {
            if (file.plugin_id === 'PROJECT_FILE_PICKER') {
                return undefined;
            }
            const fileType = filteredFileTypes.find(type => type.id === file.file_type);
            if (fileType) {
                return {
                    ...file,
                    defaultName: fileType.defaultName,
                    description: fileType.description,
                    workflowId: wrId
                };
            } else {
                return undefined;
            }
        });
        const validFiles = mergedFiles.filter((file) => file !== undefined) as FileData[];
        setDownloadData((prevData) => ({
            ...prevData,
            wfOutputData: [...(prevData.wfOutputData || []), ...validFiles],
        }));
    }

    const getAllWorkFlowOutputs = React.useCallback(async (workflowId: string) => {
        setLoading(true);
        try {
            const [filesResponse, fileTypesResponse] = await Promise.all([
                WorkflowsService.getFiles(workflowId),
                WorkflowTemplatesService.getFileTypes(),
            ]);

            const files = filesResponse.files;
            const fileTypes = fileTypesResponse.fileTypes;

            const filteredFileTypes = fileTypes.filter((type) =>
                [
                    "mosaic",
                    "dem",
                    "point_cloud",
                    "text",
                    "bft_compat_dem",
                    "stockpile_report_pdf",
                    "three_d_model_mtl_file",
                    "three_d_model_obj_file",
                    "image_jpg",
                    "geojson",
                    "dxf_file",
                    "mp4",
                    "csv"
                ].includes(type.id)
            );

            mergedData(files, filteredFileTypes, workflowId);
        } catch (err) {
            console.error("Error fetching workflow outputs:", err);
        } finally {
            setLoading(false);
        }
    }, []);

    const processedWorkflowIds = React.useRef<Set<string>>(new Set());
    const processedFlightIds = React.useRef<Set<string>>(new Set());

    useEffect(() => {
        if (!layers || layers.length === 0) return;
        const uniqWorkflowIds = Array.from(
            new Set(layers.map((layer) => layer.workflowId).filter((id): id is string => !!id))
        );

        const newWorkflowIds = uniqWorkflowIds.filter((id) => !processedWorkflowIds.current.has(id));

        if (newWorkflowIds.length > 0) {
            const fetchWorkflowOutputs = async () => {
                setLoading(true);
                try {
                    const workflowPromises = newWorkflowIds.map((wfId) => {
                        processedWorkflowIds.current.add(wfId);
                        return getAllWorkFlowOutputs(wfId);
                    });
                    await Promise.all(workflowPromises);
                } catch (error) {
                    console.error("Error fetching workflow outputs:", error);
                } finally {
                    setLoading(false);
                }
            };
            fetchWorkflowOutputs();
        }

        const fetchPdfData = async () => {
            try {
                const uniqFlightIds = Array.from(
                    new Set(layers.map((layer) => layer.flightId).filter((id): id is string => !!id))
                );
                const newFlightIds = uniqFlightIds.filter((id) => !processedFlightIds.current.has(id));
                if (newFlightIds.length > 0) {
                    const flightsPromises = newFlightIds.map((fId) => {
                        processedFlightIds.current.add(fId);
                        return ReportsService.getFlightReports(fId).then(
                            (reportsResponse) => reportsResponse.reports
                        );
                    });
                    const responses = await Promise.all(flightsPromises);
                    const allReports = responses.flat();

                    setDownloadData((prevData) => ({
                        ...prevData,
                        pdfData: [...(prevData.pdfData || []), ...allReports],
                    }));
                }
            } catch (error) {
                console.error("Error fetching PDF data:", error);
            }
        };
        fetchPdfData();

    }, [layers]);

    const downloadFile = (url: string, fileName: string) => {
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        notifySucess(`${fileName} download has started`)
    };

    const handleDownload = (workflowId: string, fileId: string, fileName: string) => {
        WorkflowsService.getFilePresignedUrl(workflowId, fileId)
            .then((url) => {
                downloadFile(url, fileName);
            })
            .catch((err) => console.error(err))
            .finally(() => setLoading(false));
    };

    const handleDownloadPdf = (wfId: string, fileId: string, fileName: string) => {
        WorkflowsService.getFilePresignedUrl(wfId, fileId)
            .then((url) => {
                downloadFile(url, fileName);
            })
            .catch((err) => console.error(err))
            .finally(() => setLoading(false));
    };

    const openPdfInNewTab = async (workflowId: string, fileId: string) => {
        try {
            const url = await WorkflowsService.getFilePresignedUrl(workflowId, fileId);
            if (!url) {
                throw new Error("Failed to retrieve the PDF URL");
            }
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error("Failed to fetch the PDF");
            }
            const pdfBlob = await response.blob();
            const pdfUrl = URL.createObjectURL(pdfBlob);
            window.open(pdfUrl, "_blank");
            setTimeout(() => URL.revokeObjectURL(pdfUrl), 60000);
        } catch (error) {
            console.error("Error opening PDF:", error);
        }
    };


    return (
        <Dialog open={visible}>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>Download Reports & Maps</DialogTitle>
                    <DialogContent style={{ margin: ".5em 0" }}>
                        {loading ? (
                            <Spinner />
                        ) : downloadData.wfOutputData.length === 0 && downloadData.pdfData.length === 0 ? (
                            <div>
                                <h1 style={{ fontSize: "1rem", fontWeight: "400" }}>
                                    No reports or maps available to download
                                </h1>
                            </div>
                        ) : (
                            <div>
                                {downloadData.wfOutputData
                                    .filter((output) => output.status === "uploaded")
                                    .map((output) => (
                                        <div className={classes.listContainer} key={output.id}>
                                            <div style={{ margin: ".5em 0" }}>
                                                <p>{output.defaultName}</p>
                                            </div>
                                            <div>
                                                <img
                                                    className={classes.img}
                                                    src={downloadLayerIcon}
                                                    alt="downloadLayer"
                                                    onClick={() =>
                                                        handleDownload(output.workflowId, output.id, output.defaultName)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    ))}
                                {downloadData.pdfData && (
                                    <div>
                                        {downloadData.pdfData.map((outputFile, index) => (
                                            <div className={classes.listContainer} key={index}>
                                                <div style={{ margin: ".5em 0" }}>
                                                    <p>{outputFile.name}</p>
                                                </div>
                                                <div>
                                                    <img
                                                        style={{ marginRight: ".8em" }}
                                                        className={classes.img}
                                                        src={newTabIcon}
                                                        alt="newTabIcon"
                                                        onClick={() =>
                                                            openPdfInNewTab(outputFile.workflowId, outputFile.id)
                                                        }
                                                    />
                                                    <img
                                                        className={classes.img}
                                                        src={downloadLayerIcon}
                                                        alt="downloadLayer"
                                                        onClick={() =>
                                                            handleDownloadPdf(
                                                                outputFile.workflowId,
                                                                outputFile.id,
                                                                outputFile.name
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary" onClick={() => toggleModal(false)}>Close</Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}

export default DownloadReports;

const useStyles = makeStyles({
    listContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    img: {
        cursor: "pointer"
    }
});
