import { Divider, makeStyles } from "@fluentui/react-components";
import Toggle2DIcon from "../../assets/icons/ol/toggle_views/Toggle2D.svg";
import Toggle2DSelectedIcon from "../../assets/icons/ol/toggle_views/Toggle2D_selected.svg";
import Toggle3DIcon from "../../assets/icons/ol/toggle_views/Toggle3D.svg";
import Toggle3DSelectedIcon from "../../assets/icons/ol/toggle_views/Toggle3D_selected.svg";
import ToggleVideoIcon from "../../assets/icons/ol/toggle_views/ToggleVideo.svg";
import ToggleVideoSelectedIcon from "../../assets/icons/ol/toggle_views/ToggleVideo_selected.svg";

export type ToggleType = "2D" | "3D" | "VIDEO"

interface ToggleViewProps {
    visible: boolean;
    visibilityConfig: {
        "3D": boolean;
        "2D": boolean;
        "VIDEO": boolean;
    }
    setCurrentView: (view: ToggleType) => void;
    currentView: ToggleType
}

export default function ToggleView(props: ToggleViewProps) {
    const { visible, visibilityConfig, setCurrentView, currentView } = props;
    const classes = useStyles();

    if (!visible) {
        return null
    }

    return <div className={currentView === "2D" ? classes.toolboxContainer : classes.absoluteToggleViewContainer}>
        {visibilityConfig["3D"] && <img src={currentView === "3D" ? Toggle3DSelectedIcon : Toggle3DIcon} alt="3D" onClick={() => { setCurrentView("3D") }} className={classes.icon} />}

        {(visibilityConfig["3D"] && (visibilityConfig["2D"] || visibilityConfig["VIDEO"])) && <Divider vertical style={{ minHeight: "32px" }} />}

        {visibilityConfig["2D"] && <img src={currentView === "2D" ? Toggle2DSelectedIcon : Toggle2DIcon} alt="2D" onClick={() => { setCurrentView("2D") }} className={classes.icon} />}

        {(visibilityConfig["VIDEO"] && (visibilityConfig["2D"] || visibilityConfig["3D"])) && <Divider vertical style={{ minHeight: "32px" }} />}

        {visibilityConfig["VIDEO"] && <img src={currentView === "VIDEO" ? ToggleVideoSelectedIcon : ToggleVideoIcon} alt="VIDEO" onClick={() => { setCurrentView("VIDEO") }} className={classes.icon} />}
    </div>
}


const useStyles = makeStyles({
    toolboxContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "8px",
        boxShadow: "-2px 2px 10px 0px rgba(0, 0, 0, 0.25)",
        backgroundColor: "white",
        "&>*:first-child": {
            borderTopLeftRadius: "8px",
            borderBottomLeftRadius: "8px",
        },
        "&>*:last-child": {
            borderTopRightRadius: "8px",
            borderBottomRightRadius: "8px",
        }
    },
    absoluteToggleViewContainer: {
        position: 'absolute',
        bottom: '40px',
        right: '20px',
        backgroundColor: "#FFF",
        borderRadius: "8px",
        boxShadow: "-2px 2px 10px 0px rgba(0, 0, 0, 0.25)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "&>*:first-child": {
            borderTopLeftRadius: "8px",
            borderBottomLeftRadius: "8px",
        },
        "&>*:last-child": {
            borderTopRightRadius: "8px",
            borderBottomRightRadius: "8px",
        }
    },
    // toggleItem: {
    //     cursor: "pointer",
    //     ...shorthands.border("none"),
    //     ...shorthands.overflow("hidden"),
    //     ...shorthands.padding("5px", "8px"),
    //     backgroundColor: "#FFF",

    // },
    icon: {
        padding: "8px",
        cursor: "pointer",
        pointerEvents: "auto",
        overflow: "hidden",
        ":hover": {
            backgroundColor: "#EEE",
        },
    }
});