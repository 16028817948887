import { Label, makeStyles, Slider, SliderOnChangeData } from "@fluentui/react-components";
import { useState } from "react";

interface MapThresholdSliderProps {
    lyr: any;
    onSliderChange: (v: number) => void;
}
export function MapThresholdSlider(props: MapThresholdSliderProps) {
    const { lyr, onSliderChange } = props;
    const classes = useStyles();
    const [sliderValue, setSliderValue] = useState((lyr.variables.threshold || 0) * 100);
    const [showValue, setShowValue] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);

    const handleMouseEnter = () => {
        setShowValue(true);
        if (timeoutId) clearTimeout(timeoutId); // Cancel hiding if mouse re-enters
    };

    const handleMouseLeave = () => {
        const id = setTimeout(() => setShowValue(false), 1000);
        setTimeoutId(id as any); // Set timeout to hide after 1 second
    };

    const handleSliderChange = (_: any, data: SliderOnChangeData) => {
        const newValue = data.value || 0;
        setSliderValue(newValue);
        onSliderChange(data.value);
    };

    return <div className={classes.thresholdSliderWrapper} style={{ position: "relative" }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
    >
        <div
            style={{
                position: "absolute",
                top: "-20px",
                left: `calc(${32 + (sliderValue / 100) * (100 - 64)}%)`, // Account for thumb position and margin
                transform: "translateX(-50%)",
                pointerEvents: "none",
                fontSize: "12px",
                backgroundColor: "white",
                padding: "2px 4px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                textAlign: "center",
                opacity: showValue ? "100%" : '0',
                transition: "opacity 0.25s",
            }}
        >
            {(
                (lyr.min || 0) + ((lyr.max || 100) - (lyr.min || 0)) * (sliderValue / 100)
            ).toFixed(2)}
        </div>
        <Label aria-hidden>{Math.trunc(lyr.min || 0)}</Label>
        <Slider
            min={0}
            max={100}
            value={(lyr.variables.threshold || 0) * 100}
            onChange={handleSliderChange}
        />
        <Label aria-hidden>{Math.trunc(lyr.max || 100)}</Label>
    </div>
}

const useStyles = makeStyles({
    thresholdSliderWrapper: {
        display: 'flex',
        alignItems: "center"
    },
});