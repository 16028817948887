import { Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, makeStyles, shorthands, Toast, Toaster, ToastTitle, useToastController } from "@fluentui/react-components";
import { Map } from "ol";
import React, { useEffect, useState } from "react";
import CrossIcon from "../assets/icons/cross.svg";
import TimelineMap from "../assets/icons/timeline_map.png";
import { FeatureDemosService, FlightResponse, MapLayerResponse, ProjectsService, ViewsService } from "../services/openapi";
import { PrimaryButton2 } from "./common/PrimaryButton2";
import DownloadReports from "./DownloadReports";
import { MapShare } from "./MapShare";
import OpenLayers from "./OpenLayers";
import { sourceTypes, TourContext } from "./TourContext";
import { getTourSteps } from "./TourSteps";

interface MapModalProps {
    visible?: boolean;
    layers?: MapLayerResponse[] | null;
    flight?: FlightResponse;
    toggleModal?: (flag: boolean) => void
    onLoaded?: (map: Map) => void;
    fullScreen?: boolean;
    type?: "modal" | "non-modal";
    projectID?: string;
    onShowCompareView?: (layers: MapLayerResponse[]) => void;
}

function convertDateFormat(inputDate?: string): string {
    if (!inputDate) {
        return ""
    }
    const inputDateObj = new Date(inputDate);

    if (isNaN(inputDateObj.getTime())) {
        throw new Error('Invalid date format');
    }

    const day = String(inputDateObj.getDate()).padStart(2, '0');
    const month = String(inputDateObj.getMonth() + 1).padStart(2, '0');
    const year = String(inputDateObj.getFullYear());

    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
}

export function MapModal(props: MapModalProps) {
    const { visible = true, toggleModal = () => null, flight, layers, onLoaded, fullScreen = false, type = "modal", projectID, onShowCompareView = () => null } = props;
    const [showFullScreen, setFullScreen] = useState<boolean>(fullScreen)
    const classes = useStyles();
    const [currentLayer, setCurrentLayer] = useState<MapLayerResponse[]>();
    const [isShareOpen, setIsShareOpen] = useState(false);
    const [shareId, setShareId] = useState<string | null>(null);
    const [shareLayerID, setShareLayerID] = useState<string[] | null>(null);
    const [showShareDisabledModal, setShowShareDisabledModal] = useState(false);
    const { startTour, source, setTourSource, isPrevious } = React.useContext(TourContext);
    const [canShare, setCanShare] = useState(false);
    const [isDownloadReportsOpen, setIsDownloadReportsOpen] = useState(false);
    const [availableFlights, setAvailableFlights] = useState<string[]>([]);
    const [accessType, setAccessType] = useState("private");
    const { dispatchToast } = useToastController("success");

    const notifySucess = (msg: string) => dispatchToast(
        <Toast
            style={{ background: "#DFF6DD", maxWidth: "600px" }}>
            <ToastTitle style={{ fontSize: "14px", fontWeight: 400 }}>{msg}</ToastTitle>
        </Toast>,
        { intent: "success" }
    );

    React.useEffect(() => {
        const stepIndex = getTourSteps().findIndex(item => item.target === "#map-timeline")
        if (visible && type === "non-modal") {
            if (source && source === sourceTypes.startOnboarding && !isPrevious) {
                startTour({ run: true, stepIndex: stepIndex });
                setTourSource(sourceTypes.startOnboarding);
                return;
            }
            FeatureDemosService.getAllFeatureDemos().then(features => {
                if (!features.featureDemos.find(item => item.name === "map")) {
                    return;
                } else {
                    FeatureDemosService.getAllFeatureDemoViews().then((features) => {
                        if (!(features?.viewedFeatureDemos.find(item => item.name === "map"))) {
                            startTour({ run: true, stepIndex: stepIndex })
                        }
                    });
                }
            })
        }
        if (layers) {
            const uniqFlightIds = Array.from(
                new Set(layers.map((layer) => layer.flightId).filter((id): id is string => !!id))
            );
            setAvailableFlights(uniqFlightIds);
        }
    }, [layers, visible, type]);

    useEffect(() => {
        let pid = flight?.projectId || projectID
        if (!pid) {
            return;
        }
        ProjectsService.checkUserProjectAccess(pid).then(permissions => {
            setCanShare(permissions.canShareMaps)
        }).catch(err => console.error("Unable to fetch project access"))
    }, [flight, projectID])

    if (!layers) {
        return <></>
    }

    const getMonthYear = (createdAt: string, monthType: "long" | "short") => {
        const date = new Date(createdAt);
        const month = date.toLocaleString('en-US', { month: monthType });
        const year = date.getFullYear();
        return `${month} ${year}`;
    };

    const getMonthDay = (createdAt: string): string => {
        const date = new Date(createdAt);
        const month = date.toLocaleString('en-US', { month: 'short' });
        const day = date.getDate();
        return `${month} ${day}`;
    };

    const sortedLayers = layers ? layers.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()) : []

    const monthWiseData: Record<string, MapLayerResponse[]> = sortedLayers.reduce((acc: Record<string, MapLayerResponse[]>, item) => {
        const monthYear = getMonthYear(item.createdAt, "long");

        if (!acc[monthYear]) {
            acc[monthYear] = [];
        }

        acc[monthYear].push(item);
        return acc;
    }, {});

    const flightWiseData: Record<string, Record<string, MapLayerResponse[]>> = {}
    let latestMapLayerTime: string;
    let latestMapLayer: MapLayerResponse[] = [];

    Object.entries(monthWiseData).forEach(([monthYear, dataItems]) => {
        const flightWise = dataItems.reduce((acc: Record<string, MapLayerResponse[]>, item) => {
            const key = item.flightId || ""
            if (!acc[key]) {
                acc[key] = [];
            }

            acc[key].push(item);
            if (!latestMapLayerTime) {
                latestMapLayerTime = item.createdAt
                latestMapLayer = acc[key]
            }
            if (Date.parse(item.createdAt) > Date.parse(latestMapLayerTime)) {
                latestMapLayerTime = item.createdAt
                latestMapLayer = acc[key]
            }
            return acc;
        }, {})
        flightWiseData[monthYear] = flightWise
    })
    const uniqueMonths = new Set(layers.map(obj => new Date(obj.createdAt).toLocaleString('en-US', { month: 'short', year: 'numeric' })));

    const showFullScreenHandler = () => {
        setFullScreen(true)
    }
    const hideFullScreenHandler = () => {
        setFullScreen(false)
    }

    const checkShareEnable = () => {
        // Only maps with RGBA layers will have share button
        if (currentLayer) {
            return !!(currentLayer.find(item => (item.layerType === "RGBA" || item.layerType === "RGBA_COG")));
        } else {
            return !!(latestMapLayer.find(item => (item.layerType === "RGBA" || item.layerType === "RGBA_COG")));
        }
    }

    const hideShareDisabledModal = () => {
        setShowShareDisabledModal(false);
    }

    const getSortedMapLayers = (layerAccess: string) => {
        const RGBALayers = (currentLayer || latestMapLayer).filter((item: MapLayerResponse) => (item.layerType === "RGBA" || item.layerType === "RGBA_COG"));
        return layerAccess === "private"
            ? (currentLayer || latestMapLayer)?.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
            : RGBALayers?.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt));
    };

    const shareClickHandler = async () => {
        if (!canShare) {
            setShowShareDisabledModal(true);
            return;
        }

        if (isShareOpen) {
            setIsShareOpen(false)
            return;
        }
        const mapLayer = getSortedMapLayers(accessType);

        if (mapLayer) {
            const ids = mapLayer.map((item) => item.id);
            setShareLayerID(ids);
            ViewsService.createShareId({ mapLayers: ids })
                .then(data => {
                    setIsShareOpen(true);
                    setShareId(data.id);
                })
                .catch(err => { console.error("Error creating share ID", err) });
        }
    }

    const handleAccessMapLayers = (layerAccess: string) => {
        const mapLayer = getSortedMapLayers(layerAccess);

        if (mapLayer) {
            const ids = mapLayer.map((item) => item.id);
            setShareLayerID(ids);
        }
    }

    const closeShare = () => {
        setIsShareOpen(false);
    }

    const handleAccessChange = (accessTypeVal: string) => {
        if (accessTypeVal) {
            setAccessType(accessTypeVal)
            handleAccessMapLayers(accessTypeVal);
        }
    }

    const hasMultipleLayers = (obj: Record<string, any>): boolean => {
        const monthKeys = Object.keys(obj);
        if (monthKeys.length > 1) {
            return true;
        }

        if (monthKeys.length === 1) {
            const flightKeys = obj[monthKeys[0]];
            if (typeof flightKeys === 'object' && flightKeys !== null) {
                const innerKeys = Object.keys(flightKeys);
                return innerKeys.length > 1;
            }
        }
        return false;
    }

    const showCompareMaps = hasMultipleLayers(flightWiseData)


    if (type === "non-modal")
        return <div className={classes.nonModalSurface} >
            <div className={classes.content}>
                <div style={{ display: "flex", height: "100%", width: "100%" }}>
                    <div style={{ height: "100%", width: isShareOpen ? "50%" : "100%", display: "flex", flexDirection: "column" }}>
                        <div className={classes.timeline} style={{
                            gridTemplateColumns: `repeat(${uniqueMonths.size},1fr)`,
                        }}>
                            {Object.entries(flightWiseData).map(([monthYear, dataItems]) => {
                                let index = -1
                                return <div className={classes.timelineContentContainer} >
                                    <div className={classes.timelineCalendarContainer} id="map-timeline">
                                        {Object.entries(dataItems).map(([flightID, layers]) => {
                                            index += 1
                                            const item = layers[0]
                                            const check = currentLayer ? currentLayer[0].id === item.id : latestMapLayer[0].id === item.id
                                            return <div className={classes.timelineCalendar} style={{ gridColumnStart: `${new Date(item.createdAt).getDate() + index}`, }} onClick={() => {
                                                setCurrentLayer(layers);
                                                closeShare();
                                            }} >
                                                <div className={classes.timelineMap} style={{ backgroundColor: check ? "#ACFCAF" : "#FFF" }}>
                                                    <img src={TimelineMap} alt="map" className={classes.timelineMapImage} />
                                                    <div className={classes.timelineMapLabel}>{getMonthDay(item.createdAt)}</div>
                                                    <div className={classes.timelineHookContainer}>
                                                        <div className={classes.timelineHook}></div>
                                                        <div className={classes.timelineHookCircle}></div>
                                                    </div>
                                                </div>
                                            </div>

                                        })}
                                    </div>
                                    <div className={classes.timeLinLabel}>{monthYear}</div>
                                </div>
                            })}
                        </div>
                        <OpenLayers layers={!currentLayer ? latestMapLayer : currentLayer} onLoaded={onLoaded} showFullScreenIcon={false} showShare={checkShareEnable()} onClickShare={shareClickHandler} isShareOpen={isShareOpen} shareDisabled={!canShare} type="non-modal" canDownloadReports={true} onClickDownloadReportVisible={setIsDownloadReportsOpen} showCompareMaps={showCompareMaps} onClickCompare={onShowCompareView} />
                    </div>

                    {isShareOpen && <MapShare layerID={shareLayerID} shareID={shareId} flight={flight} closeShare={closeShare} shareAccess={accessType} onChageAccess={handleAccessChange} />}
                    {isDownloadReportsOpen && <DownloadReports layers={!currentLayer ? latestMapLayer : currentLayer} visible={isDownloadReportsOpen} toggleModal={setIsDownloadReportsOpen} notifySucess={notifySucess} />}

                </div>

                <div style={{
                    position: "absolute",
                    top: "190px",
                    right: "48px",
                    width: "24px",
                    height: "calc(100vh - 210px)",
                    zIndex: -1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
                >
                    <div
                        style={{
                            height: "24px",
                            width: "24px",
                        }}
                        id="map-measurement-tools"
                    >
                    </div>
                </div>
                <div style={{ zIndex: 999999999 }} className={classes.toasterLayer}>
                    <Toaster inline toasterId={"success"} position="bottom" />
                </div>
            </div>

            <Dialog open={showShareDisabledModal} >
                <DialogSurface >
                    <DialogBody >
                        <DialogTitle className={classes.shareDisabledModalHeading}>You don't have access to share map feature</DialogTitle>
                        <DialogContent style={{ width: "100%", display: "block" }}>
                            <ul className={classes.shareDisabledModalText}>
                                <li className={classes.li}><p>&bull;</p> <p>Contact your company's admin to add you to the correct organization.</p></li>
                                <li className={classes.li}><p>&bull;</p>  <p>Solo user or freelancer? Reach out to our FlyghtCloud Admin at <span><a className={classes.shareDisabledModalLink} href="mailto: flyghtcloud@ideaforgetech.com">flyghtcloud@ideaforgetech.com</a></span>to unlock features.</p></li>
                            </ul>
                        </DialogContent>
                        <DialogActions>
                            <PrimaryButton2 onClick={hideShareDisabledModal} label="OK" />
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>

        </div>


    return <> <Dialog open={visible} onOpenChange={(_, d) => { toggleModal(d.open); setIsShareOpen(false) }} modalType={type}>
        <DialogSurface className={showFullScreen ? classes.fullScreenSurface : classes.surface} style={{ transition: "all 0.2s", display: "flex" }}>
            <DialogBody style={{ width: isShareOpen ? "50%" : "100%", height: "100%", gap: 0 }}>
                <DialogTitle
                    action={null}
                    className={classes.title}
                >
                    {!!flight && <div className={classes.titleHeadingContainer}>
                        <p className={classes.titleHeading}>{flight?.name}</p>
                        <p className={classes.titleSubHeading}>{`Created on ${convertDateFormat(flight?.createdAt)}`}</p>
                    </div>}
                    <img src={CrossIcon} alt="close" onClick={() => { toggleModal(false); setIsShareOpen(false) }} className={classes.cross} />
                </DialogTitle>
                <DialogContent className={classes.content}>
                    <div className={classes.timeline} style={{
                        gridTemplateColumns: `repeat(${uniqueMonths.size},1fr)`,
                    }}>
                        {Object.entries(flightWiseData).map(([monthYear, dataItems]) => {
                            let index = -1
                            return <div className={classes.timelineContentContainer}>
                                <div className={classes.timelineCalendarContainer}>
                                    {Object.entries(dataItems).map(([flightID, layers]) => {
                                        index += 1
                                        const item = layers[0]
                                        const check = currentLayer ? currentLayer[0].id === item.id : latestMapLayer[0].id === item.id

                                        return <div className={classes.timelineCalendar} style={{ gridColumnStart: `${new Date(item.createdAt).getDate() + index}`, }} onClick={() => {
                                            setCurrentLayer(layers);
                                            closeShare();
                                        }}>
                                            <div className={classes.timelineMap} style={{ backgroundColor: check ? "#ACFCAF" : "#FFF" }}>
                                                <img src={TimelineMap} alt="map" className={classes.timelineMapImage} />
                                                <div className={classes.timelineMapLabel}>{getMonthDay(item.createdAt)}</div>
                                                <div className={classes.timelineHookContainer}>
                                                    <div className={classes.timelineHook}></div>
                                                    <div className={classes.timelineHookCircle}></div>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                                <div className={classes.timeLinLabel}>{monthYear}</div>
                            </div>
                        })}
                        <div style={{ zIndex: 999999999 }} className={classes.toasterLayer}>
                            <Toaster inline toasterId={"success"} position="bottom" />
                        </div>
                    </div>

                    <OpenLayers layers={!currentLayer ? latestMapLayer : currentLayer} onLoaded={onLoaded} showFullScreen={showFullScreenHandler} hideFullScreen={hideFullScreenHandler} fullScreenState={showFullScreen} mapContainerStyles={{
                        borderBottomLeftRadius: "16px", borderBottomRightRadius: isShareOpen ? "0px" : "16px"
                    }} showShare={checkShareEnable()} onClickShare={shareClickHandler} isShareOpen={isShareOpen} shareDisabled={!canShare} onClickDownloadReportVisible={setIsDownloadReportsOpen} canDownloadReports={true} />
                </DialogContent>
            </DialogBody>

            {isShareOpen && <MapShare layerID={shareLayerID} shareID={shareId} flight={flight} closeShare={closeShare} shareAccess={accessType} onChageAccess={handleAccessChange} />}
            {isDownloadReportsOpen && <DownloadReports layers={!currentLayer ? latestMapLayer : currentLayer} visible={isDownloadReportsOpen} toggleModal={setIsDownloadReportsOpen} notifySucess={notifySucess} />}
        </DialogSurface>

    </Dialog >
        <Dialog open={showShareDisabledModal} >
            <DialogSurface >
                <DialogBody >
                    <DialogTitle className={classes.shareDisabledModalHeading}>You don't have access to share map feature</DialogTitle>
                    <DialogContent style={{ width: "100%", display: "block" }}>
                        <ul className={classes.shareDisabledModalText}>
                            <li className={classes.li}><p>&bull;</p> <p>Contact your company's admin to add you to the correct organization.</p></li>
                            <li className={classes.li}><p>&bull;</p>  <p>Solo user or freelancer? Reach out to our FlyghtCloud Admin at <span><a className={classes.shareDisabledModalLink} href="mailto: flyghtcloud@ideaforgetech.com">flyghtcloud@ideaforgetech.com</a></span>to unlock features.</p></li>
                        </ul>
                    </DialogContent>
                    <DialogActions>
                        <PrimaryButton2 onClick={hideShareDisabledModal} label="OK" />
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    </>
}

const useStyles = makeStyles({
    surface: {
        ...shorthands.padding("0"),
        minWidth: "60%",
        height: "88%",
        ...shorthands.border(0),
        ...shorthands.borderRadius("16px"),

    },
    fullScreenSurface: {
        ...shorthands.padding("0"),
        minWidth: "99%",
        height: "92%",
        ...shorthands.border(0),
        ...shorthands.borderRadius("16px"),
    },
    nonModalSurface: {
        ...shorthands.padding("0"),
        ...shorthands.border(0),
        width: '100%',
        height: '100%',
    },
    content: {
        ...shorthands.padding("0"),
        ...shorthands.margin("0"),
        width: "100%",
        height: "100%",
        ...shorthands.gap(0),
        overflowY: "hidden",
        ...shorthands.overflow("hidden"),
        display: "flex",
        flexDirection: "column",
    },
    title: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingTop: "18px",
        paddingBottom: "14px",
        paddingLeft: "24px",
        paddingRight: "24px"
    },
    titleHeadingContainer: {
        display: "flex",
        flexDirection: "column",
        ...shorthands.gap("4px"),
    },
    titleHeading: {
        color: "#000",
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "28px"
    },
    titleSubHeading: {
        color: "#000",
        fontSize: "12px",
        lineHeight: "16px"
    },
    cross: {
        cursor: "pointer",
        minHeight: "24px",
        minWidth: "24px",
        marginLeft: "auto",
    },
    timeline: {
        display: "grid",
        height: "4rem",
        width: "100%",
        backgroundColor: "#F5F9FF",

    },
    timelineContentContainer: {
        display: "flex",
        flexDirection: "column",
    },
    timelineCalendar: {
        height: "3rem",
        paddingTop: "8px",
        ...shorthands.flex("1"),
        backgroundColor: "#F5F9FF",

    },
    timeLinLabel: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "1rem",
        backgroundColor: "white",
        color: "#000",
        fontSize: "10px",
        lineHeight: "16px",
    },
    timelineMap: {
        width: "42px",
        display: "flex",
        flexDirection: "column",
        ...shorthands.borderRadius("4px"),
        boxShadow: "-1px 2px 10px 0px rgba(0, 0, 0, 0.15)",
        position: "relative",
        cursor: "pointer",
        ':hover': {
            backgroundColor: "#FFF9C1 !important"
        }
    },
    timelineMapLabel: {
        color: "#000",
        textAlign: "center",
        lineHeight: "10px",
        fontSize: "10px",
        fontWeight: 400,
    },
    timelineMapImage: {
        height: "22px",
        width: "42px",
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px"

    },
    timelineHookContainer: {
        position: "absolute",
        top: "100%",
        left: "50%",
    },
    timelineHook: {
        height: "6px",
        width: "2px",
        backgroundColor: "#5C74E6",
        transform: "translateX(-50%)"
    },
    timelineHookCircle: {
        width: "4px",
        height: "4px",
        backgroundColor: "#5C74E6",
        ...shorthands.borderRadius("50%"),
        transform: "translateX(-50%)"
    },
    timelineCalendarContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(30,1fr)",
        paddingLeft: "0.5rem",
        paddingRight: "0.5rem",
        ...shorthands.gap("0.25rem"),
        borderTop: "1px solid #A4B8D5",
        borderBottom: "1px solid #A4B8D5",
        boxShadow: "box-shadow: -1px 0px 10px 0px #00000033"
    },

    shareDisabledModalHeading: {
        color: "#3E3E3E",
        fontSize: "18px",
        fontWeight: 600,
        lineHeight: "24px",
        marginBottom: "8px"
    },
    shareDisabledModalText: {
        color: "#3E3E3E",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "24px"
    },
    shareDisabledModalLink: {
        color: "#0E84E5",
        paddingRight: "0.25rem"
    },
    li: {
        paddingLeft: "1rem",
        display: "flex",
        marginBottom: "12px",
        ...shorthands.gap("8px"),
    },
    toasterLayer: {
        '& .f1euv43f': {
            width: "30% !important",
            left: " calc(50% + 0px) !important"
        },
    },
})
