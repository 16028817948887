import { Accordion, AccordionHeader, AccordionItem, AccordionPanel, Button, Checkbox, CounterBadge, makeStyles, shorthands, Toast, Toaster, ToastTitle, tokens, useToastController } from '@fluentui/react-components';
import { Folder24Regular } from '@fluentui/react-icons';
import * as React from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import projectHeaderIcon from "../assets/icons/project_header.svg";
import { API_BASE } from '../constants';
import { FeatureDemosService, FlightsService, FlightSummaryResponse, ProjectAccessResponse, ProjectResponse, ProjectsService, WorkflowsService, WorkflowTemplatesService } from '../services/openapi';
import { FlightWorkflows } from './FlightWorkflows';
import { HeadingCard } from './HeadingCard';
import { Loading } from './Loading';
import { StartWorkflowButton } from './StartWorkflowButton';
import { sourceTypes, TourContext } from './TourContext';
import { getTourSteps } from './TourSteps';
import InsufficientCreditModal from './InsufficientCreditModal';
import { AuthContext } from '../AuthContext';

export const Flight = () => {
    const classes = useStyles();
    const [error, setError] = React.useState('');
    const [project, setProject] = React.useState<ProjectResponse | null>();
    const [flight, setFlight] = React.useState<FlightSummaryResponse | null>();
    const [autoRun, setAutoRun] = React.useState(false);

    const fileInputRef = React.createRef<HTMLInputElement>();
    const [files, _setFiles] = React.useState<File[]>([]);
    const [size, setSize] = React.useState(0);
    const maxConcurrency = 4;
    const [errors, setErrors] = React.useState<any[]>([]);

    const [fileUploadStatus, setFileUploadStatus] = React.useState({
        success: 0,
        failed: 0,
    })
    const [projectAccess, setProjectAccess] = React.useState<ProjectAccessResponse | null>(null)
    const [insufficientCreditModalVisible, setInsufficientCreditModalVisible] = React.useState(false)
    const { projectId, flightId } = useParams();
    const navigate = useNavigate();
    const { startTour, source, setTourSource, isPrevious } = React.useContext(TourContext);
    const location = useLocation();
    const promises: (Promise<any> | null)[] = [];
    const [isUploading, setIsUploading] = React.useState(false);
    const { dispatchToast } = useToastController("error");
    const { orgId, setOrgId, setIsOrganisationListUpdated } = React.useContext(AuthContext);


    const notifyError = (err: string) => dispatchToast(
        <Toast
            style={{ background: "#FDE7E9", width: "500px" }}>
            <ToastTitle style={{ fontSize: "14px", fontWeight: 400 }}>{err}</ToastTitle>
        </Toast>,
        { intent: "error" }
    );

    React.useEffect(() => {
        const stepIndex = getTourSteps().findIndex(item => item.target === "#flight-details")
        if (source && source === sourceTypes.startOnboarding && !isPrevious) {
            startTour({ run: true, stepIndex });
            setTourSource(sourceTypes.startOnboarding);
            return;
        }
        FeatureDemosService.getAllFeatureDemos().then((features) => {
            if (!(features?.featureDemos.find(item => item.name === "projects"))) {
                return;
            } else {
                FeatureDemosService.getAllFeatureDemoViews().then((features) => {
                    if (features?.viewedFeatureDemos.find(item => item.name === "projects")) {
                        return;
                    }
                    else {
                        startTour({ run: true, stepIndex })
                    }
                });
            }
        })
    }, [location.pathname, flight])

    React.useEffect(() => {
        if (!projectId) return;
        if (!flightId) return;
        ProjectsService.getProjectSummary(projectId)
            .then((ps) => {
                setProject(ps);
                if (orgId?.orgId === null || ps.organisationId !== orgId?.orgId) {
                    setIsOrganisationListUpdated({
                        isOrgListUpdate: true,
                        updatedOrgId: ps.organisationId,
                    });
                    setOrgId({ orgId: ps.organisationId });
                }
            })
            .catch((err) => setError(`Could not get project info: ${err}`));
        FlightsService.getFlightSummary(flightId)
            .then((fs) => setFlight(fs))
            .catch((err) => setError(`Could not get flight info: ${err}`));
    }, [projectId, flightId]);

    React.useEffect(() => {
        if (!projectId) return;
        ProjectsService.checkUserProjectAccess(projectId).then(permissions => {
            setProjectAccess(permissions)
        }).catch(err => console.error("Unable to fetch project access"))
    }, []);
    const canUpload = !!projectAccess && projectAccess.canUploadData;

    const setFiles = (files: File[]) => {
        const sum = [...files].map((f) => f.size / (1000.0 * 1000.0 * 1000.0)).reduce((s, v) => s + v, 0);
        setSize(sum);
        _setFiles([...files]);
    };

    const dropped = async (e: React.DragEvent<HTMLDivElement>) => {
        const supportsFileSystemAccessAPI =
            'getAsFileSystemHandle' in DataTransferItem.prototype;
        const supportsWebkitGetAsEntry =
            'webkitGetAsEntry' in DataTransferItem.prototype;

        if (!supportsFileSystemAccessAPI && !supportsWebkitGetAsEntry) {
            console.error('Unsupported drop in browser');
            return;
        }

        const promises = [...e.dataTransfer.items]
            .filter((item) => item.kind === 'file')
            .map((item) => item.webkitGetAsEntry())
            .filter((i) => !!i)
            .map((i) => traverseFileTree(i));

        const filesArray = await Promise.all(promises);
        const allFiles: any = filesArray.flat();

        setFiles(allFiles);
    };

    const traverseFileTree = (item: any, path = '') => {
        if (item.isFile) {
            return new Promise((resolve) => {
                item.file((file: any) => {
                    resolve([file]);
                });
            });
        } else if (item.isDirectory) {
            const dirReader = item.createReader();
            let entries: any[] = [];
            const readEntries = () => {
                return new Promise((resolve, reject) => {
                    dirReader.readEntries((results: any) => {
                        if (results.length) {
                            entries = entries.concat(results);
                            readEntries().then(resolve).catch(reject);
                        } else {
                            resolve(entries);
                        }
                    }, reject);
                });
            };

            return readEntries().then((allEntries: any) => {
                if (allEntries && allEntries.length) {
                    const promises: any = allEntries.map((entry: any) => traverseFileTree(entry, path + item.name + "/"));
                    return Promise.all(promises).then((results) => {
                        return results.flat();
                    });
                } else {
                    return [];
                }
            });
        }
    };

    const uploadOne = (idx: number) => {
        return new Promise((_resolve, _reject) => {
            const f = files.pop();
            if (!f) {
                promises[idx] = null;
                if (
                    promises.filter((p) => !!p).length === 0
                    && files.filter((f) => !!f).length === 0
                ) {
                    onUploadDone();
                }
                return;
            }
            window.setTimeout(() => {
                const fd = new FormData();
                fd.append('image', f);
                fetch(`${API_BASE}/api/flights/${flightId}/images`, {
                    method: 'POST',
                    headers: {

                    },
                    body: fd,
                    credentials: 'include',
                }).then((res) => {
                    if (res.status === 409) {
                        // This means server already has the file, so count as success
                        setFileUploadStatus(prev => {
                            return {
                                ...prev,
                                success: prev.success + 1,
                            }
                        })
                    } else if (res.status === 200) {
                        setFileUploadStatus(prev => {
                            return {
                                ...prev,
                                success: prev.success + 1
                            }
                        })
                        return res.json();
                    } else {
                        throw res;
                    }
                }).then((res) => {
                    return uploadOne(idx);
                }).catch((err) => {
                    errors.push({ error: err, file: f });
                    setErrors([...errors]);
                    setFileUploadStatus(prev => {
                        return {
                            ...prev,
                            failed: prev.failed + 1
                        }
                    });
                    return uploadOne(idx);
                });
            }, 100);
        })
    };

    const startUpload = () => {
        setFileUploadStatus({ success: 0, failed: 0 });
        setIsUploading(true)
        const numFiles = files.length
        for (let i = 0; i < Math.min(maxConcurrency, numFiles); i++) {
            promises.push(uploadOne(i));
        }
    };

    const onUploadDone = () => {
        setIsUploading(false)
        // Sync flight summary
        if (flightId) {
            FlightsService.getFlightSummary(flightId)
                .then((fs) => setFlight(fs))
                .catch((err) => setError(`Could not get flight info: ${err}`));
        }
        if (autoRun && flightId) {
            const defaultWorkflowAvailable = WorkflowTemplatesService.getTemplates(flightId)
                .then((ts) => {
                    const defaultWorkflowTemplate = ts.templates.find(
                        (wf) => wf.id === project?.workflowTemplate
                    );
                    return defaultWorkflowTemplate?.available || false;
                })
                .catch((err) => {
                    setError(`Could not get workflow templates: ${err}`);
                    return false;
                });
            defaultWorkflowAvailable.then((isAvailable) => {
                if (isAvailable) {
                    WorkflowsService.createDefaultFlightWorkflow(flightId)
                        .then((wf) => {
                            navigate(`/workflows/${wf.id}`);
                        })
                        .catch((err) => {
                            setError(`Could not start default workflow: ${err}`);
                            notifyError("Failed to start the workflow. Please try again.");
                        });
                } else {
                    setError("Insufficient credits to run the workflow.");
                    toggleInsufficientCreditModal(true);
                }
            });
        }
    };

    const loaded = !!project && !!flight;
    if (!loaded) {
        return <Loading />;
    }

    const showFileUploadStatus = Boolean(fileUploadStatus.failed) || Boolean(fileUploadStatus.success)

    const toggleInsufficientCreditModal = (flag: boolean) => {
        setInsufficientCreditModalVisible(flag)
    };

    return <>
        <div className={classes.projectHeaderText}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <Link style={{ textDecoration: "none" }} className={classes.flexContent} to="/projects"><img src={projectHeaderIcon} /> Projects</Link></div> <p style={{ margin: "0 .2em " }}>{'>'}</p> <Link style={{ textDecoration: "none" }} className={classes.flexContent} to={`/projects/${project.id}`}>{project.name}</Link><p style={{ margin: "0 .2em " }}>{'>'}</p> <div style={{ fontSize: '1rem' }}>{flight.name}</div>
        </div>
        <div
            style={{
                width: '98%',
                background: tokens.colorNeutralBackground1,
                borderRadius: '1em',
                padding: '1em',
                paddingRight: '3em',
                margin: "1em"
            }}
            id="flight-details"
        >
            {error && <div>{error}</div>}
            <div>
                <div>
                    <CounterBadge count={1} color="informative" style={{ marginRight: '0.75em' }} />
                    Flight:
                </div>
                <div style={{ paddingLeft: '2em' }}>
                    {flight.name}
                </div>
            </div>
            <div style={{ marginTop: '2em' }}>
                <div>
                    <CounterBadge count={2} color="informative" style={{ marginRight: '0.75em' }} />
                    Flight Date
                </div>
                <div style={{ paddingLeft: '2em' }}>
                    {flight.flightDate}
                </div>
            </div>
            {canUpload ? <Accordion collapsible defaultOpenItems={(flight.numWorkflows || 0) === 0}>
                <AccordionItem value={1}>
                    <AccordionHeader>{`Upload Geo-Tagged Images ${flight.summary?.numImages ? `(${flight.summary?.numImages} images uploaded)` : ""}`}</AccordionHeader>
                    <AccordionPanel>
                        <div>
                            <div
                                onDrop={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    dropped(e);
                                }}
                                onDrag={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                onDragOver={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                style={{
                                    padding: '5em 5em 5em 2em',
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    border: `1px dashed ${tokens.colorNeutralBackground2}`
                                }}
                            >
                                <input
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    type='file'
                                    multiple
                                    onChange={(e) => setFiles(e.target.files as any)} />
                                <div><Folder24Regular /></div>
                                <div>Drag and Drop, or
                                    <Button
                                        appearance='transparent'
                                        style={{ textDecoration: 'underline', paddingBottom: '0.7em', marginLeft: '-0.75em', paddingLeft: 0 }}
                                        onClick={() => {
                                            if (fileInputRef.current) {
                                                fileInputRef.current.click();
                                            }
                                        }}
                                    >Browse</Button>
                                </div>
                                {(files && files.length > 0) && (
                                    <>
                                        <div>
                                            {files.length} files pending for upload ({size.toFixed(3)} GB)
                                        </div>
                                        <div>
                                            <Button
                                                appearance='primary'
                                                disabled={isUploading}
                                                onClick={startUpload}
                                            >{isUploading ? "Uploading" : "Start uploading files"}</Button>
                                        </div>

                                    </>
                                )}

                                {showFileUploadStatus && <div style={{ display: "flex", gap: "0.5rem" }}>
                                    <div style={{ color: "green" }}>{`${fileUploadStatus.success} success`}</div>
                                    <div style={{ color: "red" }}>{`${fileUploadStatus.failed} failed`}</div>
                                </div>}
                            </div>

                        </div>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion> : <p style={{ padding: "12px" }}>{`(${flight.summary?.numImages} images uploaded)`}</p>}
            <div style={{ marginTop: '2em', display: 'flex', justifyContent: 'space-between' }}>
                {!project.workflowTemplate && <div></div>}
                {project.workflowTemplate && (
                    <div>
                        <div>
                            <Checkbox checked={autoRun} onChange={(_, e) => setAutoRun(!!e.checked)} disabled={!project.workflowTemplate} />
                            Automatically initiate default workflow when upload is completed
                        </div>
                        <div style={{ paddingLeft: '2.25em' }}>Default workflow: {project.workflowTemplate} {project.workflowDescription}</div>
                    </div>
                )}
                <div>
                    <StartWorkflowButton
                        appearance='primary'
                        flightId={flight.id}
                        projectId={flight.projectId}
                        flightName={flight.name}
                        groupId={flight.groupId || undefined}
                        projectName={project.name || ''}
                        buttonID='flight-details-startWorkflowBtn'
                        isDisabled={isUploading}
                        numOfFlightImages={(flight.summary?.numImages && Number(flight.summary?.numImages)) ?? 0}
                    />
                </div>
            </div>
        </div>
        {(flight.numWorkflows || 0) > 0 && (
            <div
                style={{
                    width: '98%',
                    background: tokens.colorNeutralBackground1,
                    borderRadius: '1em',
                    padding: '1em',
                    paddingRight: '3em',
                    margin: '1em',
                }}
            >
                <HeadingCard>Workflows</HeadingCard>
                <FlightWorkflows flightId={flightId || ''} />
            </div>
        )}
        <InsufficientCreditModal
            visible={insufficientCreditModalVisible}
            toggleModal={toggleInsufficientCreditModal}
        />
        <Toaster inline toasterId={"error"} position="bottom" />
    </>
};

const useStyles = makeStyles({
    projectHeaderText: {
        height: "3rem",
        paddingLeft: "1.25rem",
        display: "flex",
        alignItems: "center",
        color: "#2B2B2B",
        ...shorthands.borderBottom('1px', 'solid', '#E3E9F2'),
        '> span': {
            fontWeight: "700",
        }
    },
    flexContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "black", fontWeight: "normal"
    },
})
