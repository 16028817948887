import { makeStyles, Menu, MenuItem, MenuList, MenuPopover, MenuProps, MenuTrigger, shorthands, Switch, SwitchOnChangeData, Toast, Toaster, ToastTitle, useId, useToastController } from "@fluentui/react-components";
import { TriangleDown20Filled } from "@fluentui/react-icons";
import { Map } from "ol";
import { useEffect, useState } from "react";
import ToastSuccessIcon from "../assets/icons/toast_success.svg";
import { MapLayerResponse } from "../services/openapi";
import OpenLayers from "./OpenLayers";

interface MapComparisionViewProps {
    hideComparision: () => void;
    currentLayer: MapLayerResponse[];
    layers: MapLayerResponse[];
}

export default function MapComparisionView(props: MapComparisionViewProps) {
    const { hideComparision, layers, currentLayer } = props

    const [map1SelectOpen, setMap1SelectOpen] = useState(false)
    const [map2SelectOpen, setMap2SelectOpen] = useState(false)
    const [map1Layer, setMap1Layer] = useState(currentLayer);
    const [map2Layer, setMap2Layer] = useState(currentLayer);
    const [map1, setMap1] = useState<Map | null>(null);
    const [map2, setMap2] = useState<Map | null>(null);
    const [sync, setSync] = useState(true);
    const classes = useStyles();
    const toasterId = useId("toaster");
    const { dispatchToast } = useToastController(toasterId);

    useEffect(() => {
        let cleanup: any;
        if (sync) {
            cleanup = syncMaps(map1, map2);
        }

        return () => {
            if (cleanup) cleanup();
        };
    }, [sync, map1, map2])

    const syncToast = (message: string) => dispatchToast(
        <Toast
            style={{ background: "rgba(223, 246, 221, 1)", width: "500px" }}>
            <ToastTitle style={{ fontSize: "14px", fontWeight: 400 }} media={<img src={ToastSuccessIcon} alt="success icon" style={{ marginRight: "0.25rem" }} />}>
                {message}</ToastTitle>
        </Toast>,
        { intent: "success", timeout: 4000 }
    );


    const onMenuOpenChangeMap1: MenuProps["onOpenChange"] = (e, data) => {
        setMap1SelectOpen(data.open);
    };

    const onMenuOpenChangeMap2: MenuProps["onOpenChange"] = (e, data) => {
        setMap2SelectOpen(data.open);
    };

    function groupDataByDate(data: MapLayerResponse[]) {
        const groupedData: Record<string, MapLayerResponse[]> = {};

        data.forEach(item => {
            const date = new Date(item.createdAt).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
            });

            if (!groupedData[date]) {
                groupedData[date] = [];
            }

            groupedData[date].push(item);
        });

        return groupedData;
    }

    const groupedLayersData = groupDataByDate(layers)

    const getDateString = (layers: MapLayerResponse[]) => {
        if (layers.length === 0) {
            return ""
        }
        return new Date(layers[0].createdAt).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
        })
    }

    const loadMap1 = (map: Map) => {
        setMap1(map)
    }

    const loadMap2 = (map: Map) => {
        setMap2(map)
    }

    return <div className={classes.container}>
        <div style={{ minWidth: "50%", position: "relative" }}>
            <div className={classes.selectContainer}>
                <p className={classes.selectText}>Select Map 1</p>

                <div>
                    <Menu open={map1SelectOpen} onOpenChange={onMenuOpenChangeMap1}>
                        <MenuTrigger disableButtonEnhancement>
                            <div className={classes.menuTriggerContainer}>
                                <p className={classes.menuText}>{getDateString(map1Layer)}</p>
                                <TriangleDown20Filled style={{ color: "#5E6774" }} />
                            </div>
                        </MenuTrigger>

                        <MenuPopover className={classes.popover}>
                            <MenuList>
                                {Object.entries(groupedLayersData).map(([date, layers]) =>
                                    <MenuItem onClick={() => {
                                        setMap1Layer(layers)
                                    }}>
                                        <p className={classes.menuText}>{date}</p>
                                    </MenuItem>
                                )}

                            </MenuList>
                        </MenuPopover>
                    </Menu>
                </div >
            </div>
            <OpenLayers layers={map1Layer} showFullScreenIcon={false} mapContainerStyles={{
                height: "97%",
                paddingTop: "8px"
            }}
                onLoaded={loadMap1}
                showZoomPerc={false}
            />
        </div>

        <div style={{ width: "100%", position: "relative" }}>
            <div style={{ display: "flex", justifyContent: 'space-between' }}>
                <div className={classes.selectContainer}>
                    <p className={classes.selectText}>Select Map 2</p>

                    <div>
                        <Menu open={map2SelectOpen} onOpenChange={onMenuOpenChangeMap2}>
                            <MenuTrigger disableButtonEnhancement>
                                <div className={classes.menuTriggerContainer}>
                                    <p className={classes.menuText}>{getDateString(map2Layer)}</p>
                                    <TriangleDown20Filled style={{ color: "#5E6774" }} />
                                </div>
                            </MenuTrigger>

                            <MenuPopover className={classes.popover}>
                                <MenuList>
                                    {Object.entries(groupedLayersData).map(([date, layers]) =>
                                        <MenuItem onClick={() => {
                                            setMap2Layer(layers)
                                        }}>
                                            <p className={classes.menuText}>{date}</p>
                                        </MenuItem>
                                    )}
                                </MenuList>
                            </MenuPopover>
                        </Menu>
                    </div >
                </div>
                <div className={classes.syncToggleContainer}>
                    <p className={classes.syncText}>Sync Maps</p>
                    <Switch defaultChecked onChange={(e: any, data: SwitchOnChangeData) => {
                        setSync(data.checked);
                        if (data.checked) {
                            syncToast("Maps synced successfully! Any pan or zoom actions will now be mirrored across both maps.");
                        } else {
                            syncToast("Sync disabled: You can now navigate each map independently.");
                        }
                    }}
                        style={{ marginBottom: -8, marginTop: -8 }}
                    />
                </div>
            </div>

            <OpenLayers layers={map2Layer} showFullScreenIcon={false} mapContainerStyles={{
                height: "97%",
                paddingTop: "8px"
            }}
                showExitCompareMaps={true}
                onClickExitCompare={hideComparision}
                onLoaded={loadMap2}
                showZoomPerc={false}
            />
        </div>

        <Toaster toasterId={toasterId} position="bottom" limit={1} className={classes.toaster} />
    </div >
}

function syncMaps(map1: Map | null, map2: Map | null) {
    if (!map1 || !map2) {
        return;
    }
    const view1 = map1.getView();
    const view2 = map2.getView();

    let isSyncingFromView1 = false;
    let isSyncingFromView2 = false;

    const syncPanView1toView2 = () => {
        if (isSyncingFromView2) return;
        isSyncingFromView1 = true;
        view2.setCenter(view1.getCenter());
        isSyncingFromView1 = false;
    };

    const syncZoomView1toView2 = () => {
        if (isSyncingFromView2) return;
        isSyncingFromView1 = true;
        view2.setZoom(view1.getZoom() as number);
        isSyncingFromView1 = false;
    };

    const syncPanView2toView1 = () => {
        if (isSyncingFromView1) return;
        isSyncingFromView2 = true;
        view1.setCenter(view2.getCenter());
        isSyncingFromView2 = false;
    };

    const syncZoomView2toView1 = () => {
        if (isSyncingFromView1) return;
        isSyncingFromView2 = true;
        view1.setZoom(view2.getZoom() as number);
        isSyncingFromView2 = false;
    };

    syncPanView1toView2();
    syncZoomView1toView2();

    view1.on('change:center', syncPanView1toView2);
    view1.on('change:resolution', syncZoomView1toView2);
    view2.on('change:center', syncPanView2toView1);
    view2.on('change:resolution', syncZoomView2toView1);

    return () => {
        view1.un('change:center', syncPanView1toView2);
        view1.un('change:resolution', syncZoomView1toView2);
        view2.un('change:center', syncPanView2toView1);
        view2.un('change:resolution', syncZoomView2toView1);
    };
}

const useStyles = makeStyles({
    container: {
        width: "100%",
        height: "100%",
        maxHeight: "calc(100vh - 116.5px)",
        display: "flex",
        paddingTop: "8px",
        gap: "8px",
    },
    selectText: {
        color: "#000",
        textAlign: "center",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "20px",
    },
    menuText: {
        color: "#000",
        textAlign: "start",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "20px",
    },
    menuTriggerContainer: {
        display: "flex",
        alignItems: "center",
        gap: "64px",
        ...shorthands.padding("4px", "8px"),
        ...shorthands.border("1px", "solid", "#5E6774"),
        borderRadius: "4px",
        cursor: "pointer"
    },
    selectContainer: {
        display: "flex",
        alignItems: "center",
        gap: "8px",
        paddingLeft: "16px"
    },
    popover: {
        borderRadius: "4px",
        ...shorthands.border("1px", "solid", "#FFF"),
        width: "221px",
        padding: 0
    },
    syncToggleContainer: {
        display: "flex",
        alignItems: "center",
        gap: "16px",
        paddingRight: "16px"
    },
    syncText: {
        color: "#000",
        textAlign: "center",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
    },
    toaster: {
        // width: "350px",
    },
})
