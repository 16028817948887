import React, { useEffect, useRef } from 'react'
import HeaderNavBar from '../landing/common/HeaderNavBar'
import { makeStyles, mergeClasses } from '@fluentui/react-components'
import flyghtcloud_logo from "../../assets/icons/landing/logo_green.svg"

const PrivacyPolicy = () => {
    const classes = useStyles()
    const endRef = useRef<null | HTMLDivElement>(null);

    const scrollToBottom = () => {
        endRef.current?.scrollIntoView({ behavior: "auto" })
    }
    useEffect(() => {
        scrollToBottom()
    }, []);


    return <div ref={endRef}>
        <div className={classes.headerNavBar}>
            <HeaderNavBar icon={flyghtcloud_logo} signInBorder={true} color='black' btnBg="#E5E8EB" />
        </div>
        <div style={{ width: "40%", margin: "2em auto" }} >
            <h2 className={classes.primaryHeading}>Privacy-Policy</h2>
            <div className={classes.commonContainer}>
                <p>Effective Date: September 30, 2024</p>
            </div>
            <div className={classes.commonContainer}>
                <div className={mergeClasses(classes.flexContainer, classes.boldTxt)}>
                    <p>1.</p>
                    <p>Introduction</p>
                </div>
                <div className={classes.flexContainer}>
                    <p></p>
                    <div>
                        <p>Welcome to FLYGHT CLOUD, a software as a service application developed and provided by
                            ideaForge Technology Limited (<b>"we"</b>, <b>"our"</b>, or <b>"us"</b>). This Privacy Policy (the <b>“Policy”</b>) outlines
                            how we collect, use, disclose and protect your personal information when you use FLYGHT
                            CLOUD.
                        </p>
                        <p>Customers who avail our services are referred to in this Policy as <b>"you"</b>, <b>“your”</b>, <b>“User(s)”</b>. By
                            logging on to our website <span style={{ color: "blue", cursor: "pointer" }} onClick={() => { window.open('https://flyghtcloud.ideaforgetech.com/', "_blank"); }}><u>"https://flyghtcloud.ideaforgetech.com/"</u></span> and signing up with us, you
                            agree to provide us your information in accordance with the terms of this Policy, the FLYGHT
                            CLOUD terms of use or any other agreement that governs your use of FLYGHT CLOUD
                            (collectively the <b>“Agreements”</b>). You should not use FLYGHT CLOUD if you do not agree with the
                            terms and conditions contained in these Agreements.</p>
                    </div>
                </div>
            </div>

            <div className={classes.commonContainer}>
                <div className={mergeClasses(classes.flexContainer, classes.boldTxt)}>
                    <p>2.</p>
                    <p>Information We Collect</p>
                </div>
                <div className={classes.flexContainer}>
                    <p></p>
                    <div>
                        <p>We may collect personal identification information from you in a variety of ways, including, but
                            not limited to, when you visit our website, register on the website, place an order, respond to a
                            survey, fill out a form, and in connection with other activities, services, features or resources we
                            make available on our website.
                        </p>
                        <p>Please note, you may visit our website anonymously as well. We will collect personal
                            identification information from you only if you voluntarily submit such information to us. You
                            can always refuse to supply personally identification information, except that it may prevent you
                            from engaging in certain website related activities.</p>
                        <p>Certain services provided on FLYGHT CLOUD requires us to know who you are so that we are
                            more equipped to service your requirements. When you sign up with FLYGHT CLOUD, we may
                            ask you to voluntarily provide us with certain information, such as:</p>
                        <div className={classes.flexContainer}>
                            <p>(a)</p>
                            <p>Personal Information: When you use FLYGHT CLOUD, we may collect certain personal
                                information that you provide voluntarily, such as your name, email address, phone number,
                                organization or other contact information. We may also collect additional information from your
                                device, such as unique device identifiers, IP address, and device type.</p>
                        </div>
                        <div className={classes.flexContainer}>
                            <p>(b)</p>
                            <p>Usage Information: We may gather information about how you use FLYGHT CLOUD, including
                                the features you access, content you view, and interactions with other users.</p>
                        </div>
                        <div className={classes.flexContainer}>
                            <p>(c)</p>
                            <p>Location Information: We may collect and process location data when you use certain features
                                of FLYGHT CLOUD that require access to your device&#39;s location.</p>
                        </div><div className={classes.flexContainer}>
                            <p>(d)</p>
                            <p>Non-personal identification information: Whenever you interact with our website, non-personal
                                information such as your browser name, the type of computer and technical information about
                                your means of connection to our website, such as the operating system and the internet service
                                providers utilized and other similar information may be collected by us.</p>
                        </div>
                        <p style={{ marginTop: "1em" }}>Given the nature of the services being provided by us, we may require that you prove your
                            identity to us at the time of you registering or signing up on our website to use FLYGHT CLOUD.
                            This may involve providing us with information that we hold on file about you (e.g. your
                            organization details), or we may require you to produce some form of authorized identification.
                            We reserve the right to decline a request to register, grant or authorize your usage of FLYGHT
                            CLOUD under certain circumstances.</p>
                        <p>The information collected from you by us may constitute ‘Personal Data’ under the Digital
                            Personal Data Protection Act, 2023 (the “DPDP Act”). You hereby consent to the collection of
                            such information by us.</p>
                        <p>It is however brought to your attention that any information that is freely available or accessible
                            in the public domain shall not be regarded as Personal Data.</p>
                    </div>
                </div>
                <div className={mergeClasses(classes.flexContainer, classes.boldTxt)}>
                    <p>3.</p>
                    <p>Use of Information</p>
                </div>
                <div className={classes.flexContainer}>
                    <p></p>
                    <div>
                        <p>We may use the collected information provided by you, for the following reasons:</p>
                        <div className={classes.flexContainer}>
                            <p>(a)</p>
                            <p>Providing and Improving FLYGHT CLOUD: We use the information collected to operate,
                                maintain, and improve the functionality and user experience of FLYGHT CLOUD.</p>
                        </div>
                        <div className={classes.flexContainer}>
                            <p>(b)</p>
                            <p>Communication: We may use your contact information to communicate with you regarding
                                updates, notifications, customer support, and service-related matters.</p>
                        </div>
                        <div className={classes.flexContainer}>
                            <p>(c)</p>
                            <p>Personalization: We may use collected data to personalize your experience, including content
                                recommendations and targeted advertisements.</p>
                        </div>
                        <div className={classes.flexContainer}>
                            <p></p>
                            <p style={{ margin: "1em 1em" }}>In addition, your information may be used for the following reasons:</p>
                        </div>
                        <div className={classes.flexContainer}>
                            <p>i.</p>
                            <p>to enable you to use FLYGHT CLOUD;</p>
                        </div>
                        <div className={classes.flexContainer}>
                            <p>ii.</p>
                            <p>to help us recognize or identify you and any accounts you or your organization hold with us;</p>
                        </div>
                        <div className={classes.flexContainer}>
                            <p>iii.</p>
                            <p>to confirm your requirements verbally in the case of suspected discrepancies in service
                                information;</p>
                        </div>
                        <div className={classes.flexContainer}>
                            <p>iv.</p>
                            <p>to operate our business, including for internal purposes such as auditing, data analysis,
                                statistical and research purposes and troubleshooting to help us improve our services;</p>
                        </div>
                        <div className={classes.flexContainer}>
                            <p>v.</p>
                            <p>administer, promote, monitor and/ or comply with any issues related to your usage or
                                connection to FLYGHT CLOUD including your registration on FLYGHT CLOUD;</p>
                        </div> <div className={classes.flexContainer}>
                            <p>vi.</p>
                            <p>register and/ or maintain the account that you may open with us, including facilitating
                                purchases via FLYGHT CLOUD as a consumer of some of our services. Please be aware that while
                                availing our services, the payment gateway may be privy to your financial information, as
                                provided by you at the time of purchase of our service(s);</p>
                        </div> <div className={classes.flexContainer}>
                            <p>vii.</p>
                            <p>process transactions and send you related information (i.e., confirmations and receipts);</p>
                        </div> <div className={classes.flexContainer}>
                            <p>ix.</p>
                            <p>to analyze customer trends and insights;</p>
                        </div>
                        <div className={classes.flexContainer}>
                            <p>x.</p>
                            <p>respond to your comments, questions and requests and provide customer service;</p>
                        </div>
                        <div className={classes.flexContainer}>
                            <p>xi.</p>
                            <p>We may use the information for marketing or other purposes;</p>
                        </div><div className={classes.flexContainer}>
                            <p>xii.</p>
                            <p>deliver you information, deals, alerts, marketing and promotional materials relating to our
                                products and services;</p>
                        </div><div className={classes.flexContainer}>
                            <p>xiiii.</p>
                            <p>provide you with details about any policy adjustments, improvements and/ or amendments to
                                our policy, terms and conditions;</p>
                        </div>
                        <div className={classes.flexContainer}>
                            <p>xiv.</p>
                            <p>enter into agreements with you, and to perform our agreement to provide services to you as
                                necessary; and</p>
                        </div>
                        <div className={classes.flexContainer}>
                            <p>xv.</p>
                            <p>comply with all relevant laws and regulations or to support any government body, police entity,
                                regulatory board or related jurisdiction in law enforcement and/ or inquiries.</p>
                        </div>
                    </div>
                </div>
                <div className={mergeClasses(classes.flexContainer, classes.boldTxt)}>
                    <p>4.</p>
                    <p>Your Data, Data Sharing and Disclosure</p>
                </div>
                <div className={classes.flexContainer}>
                    <p></p>
                    <div>
                        <p><span><u>Your Data:</u></span> Any data you collect or create while using FLYGHT CLOUD belongs to you, and we
                            claim no ownership over the same. You recognize that ‘geospatial data’ you collect, may be
                            regulated and governed by the guidelines issued from time to time by the Ministry of Science &;
                            Technology, Department of Science & Technology (DST). Geospatial data includes, but is not
                            limited to, data relating to location information about natural or man-made, physical or
                            imaginary features whether above the ground or below, boundaries, points of interest, natural
                            phenomena, mobility data, weather patterns and statistical information. You shall, at all times,
                            be aware of and abide by the applicable laws relating to recording, storing and sharing of
                            geospatial data.</p>
                        <p><u>Data Sharing and Disclosure:</u></p>
                        <div className={classes.flexContainer}>
                            <p>(a)</p>
                            <p>Third-Party Service Providers: FLYGHT CLOUD has collaborated with certain third-party
                                providers, to provide you with enhanced services. If you opt to avail the services of any third
                                party provider, we may share your information with such third-party service providers, to
                                provide you with the services opted for by you.</p>
                        </div>
                        <div className={classes.flexContainer}>
                            <p>(b)</p>
                            <p>Legal Compliance: We may disclose your information if required by law or in response to a legal
                                request or investigation, including to enforce our rights, protect our users&#39; safety, and prevent
                                fraud or illegal activities. Please be aware that collection of geospatial data is regulated by the
                                Government.</p>
                        </div>
                        <div className={classes.flexContainer}>
                            <p>(c)</p>
                            <p>Financial Institutions: To financial institutions for the purpose of payments and transactions
                                related to provision of our services.</p>
                        </div>
                        <div className={classes.flexContainer}>
                            <p>(d)</p>
                            <p>Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets,
                                your information may be transferred to the acquiring entity.</p>
                        </div>
                        <div className={classes.flexContainer}>
                            <p></p>
                            <p>You acknowledge that your information may be shared with the above persons and consent to
                                us sharing the same.</p>
                        </div>
                    </div>
                </div>
                <div className={mergeClasses(classes.flexContainer, classes.boldTxt)}>
                    <p>5.</p>
                    <p>Data Security</p>
                </div>
                <div className={classes.flexContainer}>
                    <p></p>
                    <div>
                        <p>We employ procedural and technological security measures, which are reasonably designed to
                            help protect your information from loss, unauthorized access, misuse, alteration or disclosure.
                            We may use encryption, firewalls, passwords, and physical security measures to help protect
                            your information.
                        </p>
                        <p>However, no data transmission over the internet or electronic storage method can be
                            guaranteed to be 100% secure. We encourage you to take appropriate precautions to protect
                            your personal information.</p>
                        <p>We understand that you may like to know what Personal Data we hold about you, or to correct
                            or delete certain information we hold about you. We are happy to assist you with your request.
                            To protect your information, we may require that you prove your identity to us at the time your
                            request is made. This may involve providing us with information that we hold on file about you
                            (e.g. a home or business address), or we may require you to produce some form of photo or
                            government issued identification. We reserve the right to decline a request to access, correct or
                            delete your Personal Data under certain circumstances as permitted by applicable law. In
                            particular, there may be a variety of legitimate business reasons why we may be unable to
                            delete your Personal Data and where we are permitted to decline your request to delete your
                            information.</p>
                        <p>We cannot guarantee that our services and FLYGHT CLOUD will function in an error free manner
                            and without any interruptions. We shall not be liable for damages that may result from the use
                            of electronic means of communication, including, but not limited to, damages resulting from the
                            failure or delay in delivery of electronic communications, interception or manipulation of
                            electronic communications by third parties or by computer programs used for electronic
                            communications and transmission of viruses.</p>
                        <p>We will only retain your information for as long as reasonably necessary to fulfil the purposes
                            we collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting
                            or reporting requirements. All personal information we collect about you will be deleted from
                            our App after one year of your account being marked as ‘inactive’, provided that we may retain
                            your personal data for a longer period in the event of a complaint or if we reasonably believe
                            there is a prospect of litigation in respect to our relationship with you.</p>
                        <p>If it is brought to our knowledge that your usage of FLYGHT CLOUD is contrary to applicable law,
                            you shall be liable to indemnify us for any losses suffered by us in this regard and we retain the
                            right to share any information collected from you, during your usage of our services.</p>
                    </div>
                </div>
                <div className={mergeClasses(classes.flexContainer, classes.boldTxt)}>
                    <p>6.</p>
                    <p>Your Choices</p>
                </div>
                <div className={classes.flexContainer}>
                    <p></p>
                    <div>
                        <div className={classes.flexContainer}>
                            <p>(a)</p>
                            <p>Account Settings: You can review and modify your account settings within FLYGHT CLOUD to
                                control the information you provide to us. If you are using FLYGHT CLOUD as part of an
                                organization, changes to your account settings may be directed by the organization admin as
                                well.</p>
                        </div>
                        <div className={classes.flexContainer}>
                            <p>(b)</p>
                            <p>Marketing Communications: You may opt-out of receiving marketing communications from us
                                by following the unsubscribe instructions provided in our emails or contacting us directly.</p>
                        </div>
                        <div className={classes.flexContainer}>
                            <p></p>
                            <p>Although the changes to your account will be processed as quickly as possible within a
                                reasonable amount of time, please understand that you may receive emails and/ or newsletters
                                until these changes have been processed. Also, note that opting-out does not apply to any
                                communications necessary for the processing of your order. Please note that you may not be able to opt out of service-related communications (e.g., account verification, transactional
                                communications, technical and security and notices).</p>
                        </div>
                    </div>
                </div>
                <div className={mergeClasses(classes.flexContainer, classes.boldTxt)}>
                    <p>7.</p>
                    <p>Children's Privacy</p>
                </div>
                <div className={classes.flexContainer}>
                    <p></p>
                    <p>FLYGHT CLOUD is not intended for use by individuals under the age of 18 (eighteen) years. We
                        do not knowingly collect personal information from minors, without consent from their
                        guardians.</p>
                </div>
                <div className={mergeClasses(classes.flexContainer, classes.boldTxt)}>
                    <p>8.</p>
                    <p>Changes to this Privacy Policy</p>
                </div>
                <div className={classes.flexContainer}>
                    <p></p>
                    <p>We may update this Privacy Policy from time to time to reflect changes in our practices and legal
                        requirements. It is your responsibility to review this Policy periodically and update yourself with
                        any changes made to the Policy.</p>
                </div>
                <div className={mergeClasses(classes.flexContainer, classes.boldTxt)}>
                    <p>9.</p>
                    <p>Your Consent and Acceptance of this Policy</p>
                </div>
                <div className={classes.flexContainer}>
                    <p></p>
                    <p>By usage of FLYGHT CLOUD you will be deemed to have read, understood and agreed to the
                        practices and policies outlined in this Policy and agree to be bound by this Policy. You hereby
                        consent to our collection, use and sharing, disclosure of your information as described in this
                        Policy. If you use FLYGHT CLOUD on behalf of another person or entity, you represent that you
                        are authorised by such person or entity to (i) accept this Policy on such person’s or entity’s
                        behalf, and (ii) consent on behalf of such person or entity to our collection, use and disclosure of
                        such person’s or entity’s information as described in this Policy.</p>
                </div>
                <div className={mergeClasses(classes.flexContainer, classes.boldTxt)}>
                    <p>10.</p>
                    <p>Contact Us</p>
                </div>
                <div className={classes.flexContainer}>
                    <p></p>
                    <p>If you have any questions, concerns, or request regarding this Policy or the use of your personal information, please contact us at
                        <span style={{ color: "blue", cursor: "pointer" }} onClick={() => { window.open('flyghtcloud@ideaforgetech.com', "_blank"); }}><u> flyghtcloud@ideaforgetech.com</u></span>
                    </p>
                </div>
                <div className={mergeClasses(classes.flexContainer, classes.boldTxt)}>
                    <p>11.</p>
                    <p>Governing Law and Jurisdiction</p>
                </div>
                <div className={classes.flexContainer}>
                    <p></p>
                    <p>This Policy shall be governed in all respects by the laws of India and the courts at Mumbai shall
                        have jurisdiction for any dispute arising hereunder.</p>
                </div>
                <div className={mergeClasses(classes.flexContainer, classes.boldTxt)}>
                    <p>12.</p>
                    <p>Disclaimer</p>
                </div>
                <div className={classes.flexContainer}>
                    <p></p>
                    <p>While every effort is made to ensure the accuracy of information offered on these pages, we
                        shall have no liability for errors, omissions or inadequacies in the information contained herein
                        or for interpretations thereof. FLYGHT CLOUD could include inaccuracies or outdated
                        information and is subject to change at any time without notice. Your use of FLYGHT CLOUD is at
                        your own risk.</p>
                </div>
                <div className={classes.flexContainer} style={{ marginTop: "1em" }}>
                    <p></p>
                    <p>THE INFORMATION, DOCUMENTATION, SOFTWARE, PRODUCTS, AND SERVICES CONTAINED ON
                        FLYGHT CLOUD ARE PROVIDED &quot;AS IS&quot; WITHOUT ANY WARRANTY OF ANY KIND. WE AND OUR
                        OFFICERS, EMPLOYEES AND AGENTS DISCLAIM ALL WARRANTIES RELATING TO THE
                        INFORMATION, DOCUMENTATION, SOFTWARE, PRODUCTS, AND SERVICES CONTAINED ON
                        FLYGHT CLOUD, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY
                        WARRANTIES AGAINST INFRINGEMENT OF THIRD PARTY RIGHTS, MERCHANTABILITY AND
                        FITNESS FOR A PARTICULAR PURPOSE. WE SHALL NOT BE LIABLE FOR DAMAGES, WHETHER
                        DIRECT, INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL, AS A RESULT OF THE
                        REPRODUCTION, MODIFICATION, DISTRIBUTION OR USE OF THE INFORMATION,
                        DOCUMENTATION, SOFTWARE, PRODUCTS AND SERVICES.</p>
                </div>
            </div>
        </div>
    </div>
}

const useStyles = makeStyles({
    headerNavBar: {
        position: "sticky",
        top: 0,
        width: "100%",
        backgroundColor: "white",
        zIndex: 999
    },
    primaryHeading: {
        fontSize: "1.5rem",
        fontWeight: "500"
    },
    commonContainer: {
        marginTop: "2em",
        fontSize: "1rem",
        color: "#0d1314",
        lineHeight: "1.5rem"
    },
    boldTxt: {
        fontWeight: "500",
        fontSize: "1.3rem",
        marginTop: "1em",
        marginBottom: "1em"
    },
    footerTxt: {
        fontWeight: "500",
        fontSize: "1rem",
    },
    ulTag: {
        '> li': {
            listStyleType: "disc"
        },
    },
    flexContainer: {
        display: "flex",
        // marginTop: "1em",    
        textAlign: "justify",
        '> p:first-child': {
            width: "5%",
        },
        '> p:last-child': {
            width: "95%",

        },
        '> div:last-child': {
            width: "95%",
            '> p': {
                marginBottom: "1em",
            },
        },
    },
})

export default PrivacyPolicy
