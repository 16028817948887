import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle } from "@fluentui/react-components";
import Illustration from "../../assets/icons/tour_guide_completed.svg";
import BrandName from "../common/BrandName";
import { useModalStyles } from "./FlyghtCloudDemoModal";

interface Props {
    onSkip: () => void;
    onContinue: () => void;
    visible: boolean
}

export function TourStartModal({ onSkip, onContinue, visible }: Props) {
    const classes = useModalStyles();
    return <Dialog open={visible} >
        <DialogSurface className={classes["dialog-surface"]} style={{ width: "900px", height: "462px" }}>
            <DialogBody className={classes.body}>
                <DialogTitle action={null} className={classes.title}><BrandName /> Onboarding Tour!</DialogTitle>
                <DialogContent className={classes.content}>
                    <div>
                        <p className={classes.text}>To help you get started, we are going to begin a quick
                            onboarding tour to easily understand the <BrandName /> platform.</p>

                        <p className={classes.text}>You can click "Continue" to start the tour or "Skip" to explore on your own.</p>

                    </div>
                    <img src={Illustration} alt="demo" />
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <Button className={classes.secondaryButton} onClick={onSkip}>Skip</Button>
                    <Button className={classes.primaryButton} appearance="primary" onClick={onContinue}>Continue</Button>
                </DialogActions>
            </DialogBody>
        </DialogSurface>
    </Dialog >
}


