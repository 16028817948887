import { Accordion, AccordionHeader, AccordionItem, AccordionPanel, Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Field, Input, makeStyles, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, Popover, PopoverSurface, PopoverTrigger, Spinner, tokens } from "@fluentui/react-components";
import React, { useContext } from "react";
import validator from "@rjsf/validator-ajv8";
import { OrganisationsService, PluginResponse, PluginsService, ProjectResponse, ProjectsService, WorkflowTemplateResponse, WorkflowTemplatesService } from "../../services/openapi";
import { WorkflowCard } from "../WorkflowCard";
import { AuthContext } from "../../AuthContext";
import moreInfoIcon from "../../assets/icons/moreInfo.svg";
import { getSchema, NodeSchema, uiSchema } from "../utils/getSchema";
import Form from "@rjsf/fluentui-rc";

interface ModalProps {
    visible: boolean;
    toggleModal: (flag: boolean) => void;
    project: ProjectResponse;
    load: (page: number, orgId: string) => void;
}

const defaultWorkflowIDs = ["DEFAULT_MAPPING", "METASHAPE_MAPPING", "METASHAPE_MAPPING_W_GCPS", "ODM_MAP_STOCKPILE_REPORT", "METASHAPE_BFT_OUTPUT"]

const EditProjectDetailsModal = (props: ModalProps) => {
    const { orgId } = useContext(AuthContext);
    const classes = useStyles();
    const [isValidMail, setIsValidMail] = React.useState(false);
    const { visible, project, toggleModal, load } = props;
    const [form, setForm] = React.useState<{
        name: string;
        workflowTemplate?: string | null;
        organisationId: string;
        config: Record<string, any>;
    }>({
        name: project.name || '',
        workflowTemplate: project.workflowTemplate || null,
        organisationId: project.organisationId || '',
        config: {},
    });
    const [templates, setTemplates] = React.useState<WorkflowTemplateResponse[]>([]);
    const [schemaList, setSchemaList] = React.useState<NodeSchema[]>([]);
    const [plugins, setPlugins] = React.useState<Array<PluginResponse>>();
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        if (!visible) return;
        setIsLoading(true);
        WorkflowTemplatesService.getTemplates()
            .then((ts) => {
                const defaultTemplates = ts.templates.filter((t) => defaultWorkflowIDs.includes(t.id));
                setTemplates(defaultTemplates);
            })
            .catch((err) => console.error(`Could not get workflow templates: ${err}`))
            .finally(() => setIsLoading(false));

        if (!plugins?.length) {
            setIsLoading(true);
            PluginsService.getPlugins()
                .then((plugins) => setPlugins(plugins.plugins))
                .catch((err) => console.error("ERROR GETTING PLUGINS : ", err))
                .finally(() => setIsLoading(false));
        }
    }, [visible]);

    React.useEffect(() => {
        if (!visible || !project.workflowTemplate) return;

        setIsLoading(true);
        WorkflowTemplatesService.getTemplate(project.workflowTemplate)
            .then((t) => {
                setForm((prev) => ({ ...prev, workflowTemplate: t.id }));
                getSchema(
                    t,
                    (updatedSchemaList) => {
                        OrganisationsService.getWorkflowTemplateConfigs(project.organisationId, project.id, form.workflowTemplate)
                            .then((configs) => {
                                const configData = configs[0]?.config || {};
                                const updatedListWithDefaults = updatedSchemaList.map((nodeSchema) => {
                                    const nodeId = nodeSchema.nodeId;
                                    if (configData[nodeId]) {
                                        const properties = nodeSchema.schema.properties || {};
                                        Object.keys(properties).forEach((key) => {
                                            if (configData[nodeId][key] !== undefined) {
                                                properties[key].default = configData[nodeId][key];
                                            }
                                        });
                                    }
                                    return nodeSchema;
                                });

                                setSchemaList(updatedListWithDefaults);
                                const initialConfig: Record<string, any> = {};
                                updatedListWithDefaults.forEach((nodeSchema) => {
                                    const nodeId = nodeSchema.nodeId;
                                    const defaultValues = nodeSchema.schema.properties || {};
                                    const formData: Record<string, any> = {};
                                    Object.keys(defaultValues).forEach((key) => {
                                        formData[key] = defaultValues[key].default || '';
                                    });
                                    initialConfig[nodeId] = formData;
                                });

                                setForm((prev) => ({
                                    ...prev,
                                    config: initialConfig,
                                }));
                            })
                            .catch((error) => {
                                console.error("Error fetching workflow template configs:", error);
                            })
                            .finally(() => setIsLoading(false));
                    },
                    plugins
                );
            })
            .catch((err) => console.error(`Could not get workflow template: ${err}`))
            .finally(() => setIsLoading(false));
    }, [visible, project.workflowTemplate, plugins]);

    const handleSubmit = (ev: React.FormEvent) => {
        ev.preventDefault();
        ProjectsService.editProjectDetails(project.id, form).then((res) => {
            toggleModal(false);
            if (orgId?.orgId) {
                load(0, orgId?.orgId)
            }
        }).catch((err) => {
            setIsValidMail(true)
        });
    };

    const getTemplateName = () => {
        const t = (templates || []).find((t) => t.id === form?.workflowTemplate);
        return t?.name || '';
    };

    return (
        <Dialog
            open={visible}
        >
            <DialogSurface>
                <DialogTitle>Edit Project Details</DialogTitle>
                {
                    isLoading ? <Spinner /> : <form onSubmit={handleSubmit}>
                        <DialogBody>
                            <DialogContent >
                                <div style={{ marginTop: '2em' }}>
                                    <div className={classes.inputName}>
                                        Project Name *
                                    </div>
                                    <div>
                                        <Field validationMessageIcon={null} validationMessage={isValidMail ? "This project name already exists. Please choose a different name." : null}>
                                            <Input
                                                style={{
                                                    width: '100%',
                                                    border: isValidMail ? "1px solid red" : "none"
                                                }}
                                                className={classes.customInput}
                                                required id={"name-input"} value={form.name} onChange={(e) => {
                                                    setIsValidMail(false)
                                                    setForm(prev => { return { ...prev, name: e.target.value } })
                                                }} />
                                        </Field>
                                    </div>
                                </div>
                                <div style={{ marginTop: '2em' }}>
                                    <div className={classes.inputName} style={{ display: "flex", alignItems: "center" }}>
                                        Default Workflow
                                        <Popover withArrow>
                                            <PopoverTrigger disableButtonEnhancement>
                                                <img style={{ marginLeft: ".5em", cursor: "pointer" }} src={moreInfoIcon} />
                                            </PopoverTrigger>
                                            <PopoverSurface className={classes.popoverSurface} tabIndex={-1} style={{ width: "20%" }}>
                                                <p>Default workflows are pre-built templates designed by industry experts, offering a quick start for common data analysis tasks.</p>
                                            </PopoverSurface>
                                        </Popover>
                                    </div>
                                    <Menu positioning={'below-start'}>
                                        <MenuTrigger     >
                                            <div>
                                                <Button size='large' style={{ width: "100%", height: "2rem", background: "#ECF3FF", border: "none", paddingTop: "1.3em", paddingBottom: "1.3em", borderRadius: "4px", justifyContent: "flex-start", fontWeight: "normal", fontSize: "14px" }}>
                                                    {getTemplateName() || "-- Select One --"}
                                                </Button>
                                            </div>
                                        </MenuTrigger>
                                        <MenuPopover style={{ width: "550px", maxWidth: "550px" }} className={classes.dropdownPopover}>
                                            <MenuList style={{ overflowY: 'auto', maxHeight: '20em' }}>
                                                {templates.map((t) => {
                                                    return <MenuItem
                                                        key={t.id}
                                                        style={{
                                                            backgroundColor: tokens.colorNeutralBackground1,
                                                            minWidth: "100%"
                                                        }}
                                                        onClick={() => {
                                                            setForm({ ...form, workflowTemplate: t.id });
                                                            getSchema(t, setSchemaList, plugins)
                                                        }}
                                                    >
                                                        <WorkflowCard template={t} />
                                                    </MenuItem>
                                                })}
                                            </MenuList>
                                        </MenuPopover>
                                    </Menu>
                                    {(schemaList && schemaList.length > 0) && <Accordion collapsible>
                                        <AccordionItem value="1">
                                            <AccordionHeader>Config</AccordionHeader>
                                            <AccordionPanel>
                                                <Accordion collapsible>
                                                    {schemaList.map((nodeSchema, idx) =>
                                                        <AccordionItem value={idx}>
                                                            <AccordionHeader>{nodeSchema.nodeId}</AccordionHeader>
                                                            <AccordionPanel >
                                                                <Form
                                                                    formData={form.config[nodeSchema.nodeId] || {}}
                                                                    schema={nodeSchema.schema}
                                                                    validator={validator}
                                                                    onChange={(e) => {
                                                                        const nodeForm = {} as any;
                                                                        if (e.formData) {
                                                                            nodeForm[nodeSchema.nodeId] = e.formData;
                                                                        }
                                                                        setForm(prev => ({
                                                                            ...prev,
                                                                            config: {
                                                                                ...prev.config,
                                                                                ...nodeForm
                                                                            }
                                                                        }))
                                                                    }}
                                                                    uiSchema={uiSchema}
                                                                />
                                                            </AccordionPanel>
                                                        </AccordionItem>
                                                    )}
                                                </Accordion>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    </Accordion>}
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <DialogTrigger disableButtonEnhancement>
                                    <Button appearance="secondary" onClick={() => toggleModal(false)}>Close</Button>
                                </DialogTrigger>
                                <Button type="submit" appearance="primary">
                                    Submit
                                </Button>
                            </DialogActions>
                        </DialogBody>
                    </form>
                }
            </DialogSurface>
        </Dialog >
    );
};

export default EditProjectDetailsModal;

const useStyles = makeStyles({
    inputName: {
        marginTop: "1em",
        marginBottom: "1em",
        fontWeight: "500",
        fontSize: "1.1rem"
    },
    customInput: {
        padding: ".6em",
        border: "none",
        background: "#ECF3FF",
        borderRadius: "4px",
    },
    popoverSurface: {
        transform: "translate3d(0,0, 0, 0) !important",
        boxShadow: " -1px 1px 10px 0px #00000040",
    },
    dropdownPopover: {
        width: "100% !important",
    },
})